import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import  { Item, Slider } from '../components/main'
import { cap } from '../params'
import {  Row, Col, Alert } from 'react-bootstrap'
import music from '../assets/music/dandy.aac'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'
import '../style/adit.css'
import path from '../assets/img/adit/path1.svg'
import path3 from '../assets/img/adnan/path3.svg'
import cincin from '../assets/img/adit/cincin.svg'
import logoig from '../assets/img/adit/logoig.svg'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { gambar } from '../params'

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.withfriend = React.createRef()

        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            err: [],
            submitted: '',
            sesi: 1,
            hadir: false
        }
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 1000
        });
        var countDownDate = new Date("12/05/2020").getTime();
        var x = setInterval(() => {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);

    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();

        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }
    googleCalendar = () => {
        window.open(`https://calendar.google.com/calendar/r/eventedit?
  text=Pernikahan%20Dandy%20dan%20Ghina
  &details=Pernikahan%20Dandy%20dan%20Ghina
  &dates=20201205T100000/20201205T113000
  &ctz=Indonesia%2FEast_Java&title=Pernikahan%20Dandy%20dan%20Ghina
      `, '_blank')
    }
    handleSubmit = async () => {
        console.log(this.nama.current.value,  this.withfriend.current.value)
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "dandy-ghina", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }

    render() {
        let query = this.useQuery().get('name');
        query = query ? cap(query) : ''
        let { days, hours, minutes, seconds, hide, hadir, err, submitted, sesi } = this.state
        return (
            <>
                <Helm
                    title='Undanganku - Ghina & Dandy'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                />
                <div id='adit'>
                    <div id='dandy'>
                        <Container fluid id='g3-headerance' className='relative' style={{
                            backgroundImage: `url(${gambar('https://i.ibb.co/q1Q5fQV/Modal-Depan-Belakang.jpg', 95,
                                'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1800&h=1138')})`
                        }}>
                            <Item>
                                <Col xs={12} md={5} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2' 
                                    src={gambar('https://i.ibb.co/61Gg5N0/Logo-2-Fix-1.png')} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {query ? (
                                    <h2 className={`col-md-4 roboto-slab`}> Kepada : {query} </h2>

                                ) : false}
                            </Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}
                                    className={`btn col-md-4 button roboto-slab mt-0 ${hide ? 'show' : 'hide'}`}>
                                    Open Invitation
                            </div>
                            </Row>
                            <Col className={`absolute path ${hide ? 'hide' : 'show'}`} xs={12}>
                                <img className='img-fluid w-100' src={path} />
                            </Col>
                        </Container>
                    </div>
                    {!hide ? (
                        <>
                            <Container fluid>
                            </Container>
                            <Container fluid className='bg-green pt-3 pb-3' id='top'>
                                <Item>
                                    <Col xs={4} md={2} className='p-3 p-md-5'>
                                        <img src={cincin} className='img-fluid w-100' data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8}>
                                        <p className='italic roboto-slab c-main' data-aos="fade-right">
                                            بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم
                                    <br /><br />
                                    وَمِنْ ءَايَٰتِهِۦٓ أَنْ خَلَقَ لَكُم مِّنْ أَنفُسِكُمْ أَزْوَٰجًا لِّتَسْكُنُوٓا۟ إِلَيْهَا وَجَعَلَ بَيْنَكُم مَّوَدَّةً وَرَحْمَةً ۚ إِنَّ فِى ذَٰلِكَ لَءَايَٰتٍ لِّقَوْمٍ يَتَفَكَّرُونَ
                                    <br />
                                        </p>
                                        <p className='italic roboto-slab c-main f-hu' data-aos="fade-right">
                                            “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.” <br /><br />(Ar-Rum: 21)
                                    </p>
                                    </Col>
                                </Item>
                            </Container>
                            <Container id='pasangan'>

                                <Item>
                                    <div className='p-3'>
                                        <Item>
                                            <Col xs={12} md={10} className='p-3 rounded' data-aos="fade-left">
                                                <p className='c-main tinos'>
                                                    Tanpa mengurangi rasa hormat, bermaksud menyampaikan pemberitahuan kepada Bapak/Ibu/Saudara/i mengenai acara kami
                                                    </p>
                                                <Item>
                                                    <h1 className='c-main garamond p-3'>
                                                        Ghina Febrina, S.Ak.
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='s-bold tinos'>Putri ketiga dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className='tinos'>

                                                        Bapak Drs. Ramdiah Karsa Pribadi, MM. <br />
                                            dan<br />
                                            Ibu Dini Handayani
                                        </p>
                                                </Item>
                                                <Item>
                                                    <h1 className='c-main lobster p-3 larger'>
                                                        &
                                                    </h1>
                                                </Item>

                                                

                                                <Item>
                                                    <h1 className='c-main garamond p-3'>
                                                        Dandy Christian, S.Tr.
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className=' s-bold tinos'>Putra kedua dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className=' tinos'>

                                                        Bapak Deddy Kristiana Hardi, A.Md.<br />
                                                dan<br />
                                                Ibu Dewi Rukmini, A.Md.
                                                </p>
                                                </Item>
                                            </Col>
                                        </Item>
                                    </div>
                                </Item>
                            </Container>
                            <Container fluid className='bg-white p-4' id='save'>
                                <h1 className='sacramento s-bold c-main' data-aos="fade-right">
                                    Save The Date
                                </h1>
                                <Item>
                                    <Col xs={10} md={6} className='p-3 rounded  s-bold c-main' data-aos="fade-left">
                                        <Item>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {days}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Days
                                        </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {hours}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Hours
                                            </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div >
                                                        {minutes}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Mins
                                            </span>
                                                </Item>
                                            </div>
                                            <div className='dot' >:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {seconds}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Secs
                                            </span>
                                                </Item>
                                            </div>
                                        </Item>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='p-3 border-pink rounded'>
                                        <Item>
                                            <Col xs={4} md={2} className='p-3'>
                                                <img src={cincin} className='img-fluid w-100' data-aos="fade-right" />
                                            </Col>
                                        </Item>
                                        <h1 className='sacramento s-bold mt-3 c-main f-mid' data-aos="fade-left">
                                            Akad Nikah
                                            </h1>
                                        <p className='f-small poppins mt-4' data-aos="fade-left">
                                            <div className='s-bold '>
                                                Sabtu, 05 Desember 2020
                                            </div><br />
                                            <span className='m-2'>09.00 WIB - selesai </span><br />
                                            <br />
                                            <b>KEDIAMAN MEMPELAI WANITA</b> <br /><br />
                                            Jl. Bunga Rampai IX No 7 RT/RW 012/006, Perumnas Klender, Jakarta Timur.<br />
                                            Duren Sawit, Kota Jakarta Timur, DKI Jakarta, 13460<br />

                                        </p>
                                        <h1 className='sacramento s-bold mt-3 c-main f-mid' data-aos="fade-left">
                                            Ramah Tamah
                                        </h1>
                                        <p className='f-small poppins mt-4' data-aos="fade-left">
                                            <div className='s-bold '>
                                                Sabtu, 05 Desember 2020
                                            </div><br />
                                            <span className='m-2'>Sesi 1 : 11.00 WIB - 12.00 WIB </span><br />

                                            <span className='m-2'>Sesi 2 : 12.00 WIB - 13.00 WIB </span><br />

                                            <span className='m-2'>Sesi 3 : 13.00 WIB - 14.00 WIB </span><br />

                                            <span className='m-2'>Sesi 4 : 14.00 WIB - 15.00 WIB </span><br />
                                            <br />
                                            <b>KEDIAMAN MEMPELAI WANITA</b> <br /><br />
                                            Jl. Bunga Rampai IX No 7 RT/RW 012/006, Perumnas Klender, Jakarta Timur.<br />
                                            Duren Sawit, Kota Jakarta Timur, DKI Jakarta, 13460<br />

                                        </p>
                                        <Item>
                                            <Col onClick={() => { window.open('https://goo.gl/maps/mWEghao1TtzAktqi6') }} xs={8} md={4} className='button poppins c-white f-small rounded p-1 btn'  data-aos="zoom-in">
                                                Get Direction
                                    </Col>
                                        </Item>
                                        <Item>
                                            <Col
                                                onClick={() => {
                                                    this.googleCalendar()
                                                }}
                                                xs={8} md={4} className=' btn button poppins c-white f-small rounded p-1 mt-3 '  data-aos="zoom-in">
                                                Add to Google Calendar
                            </Col>

                                        </Item>
                                        <Item>
                                        <p>
                                        
                                        merupakan suatu penghormatan dan kebahagiaan bagi kami  sekeluarga apabila Bapak/Ibu/Saudara/i dapat memberikan doa restu kepada kedua mempelai.atas doa restunya, kami ucapkan terima kasih
                                        
                                        </p>
                                        </Item>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} >
                                        <Alert variant='warning text-justify' className='f-14  mt-3'>
                                            <p><b>Mohon untuk mematuhi protokol kesehatan dan aturan berikut :
                                            </b>
                                                <ol className='pl-2 pt-2'>
                                                    <li>Wajib memakai masker</li>
                                                    <li>Dilarang membawa anak kecil dan lansia</li>
                                                    <li>Satu undangan hanya berlaku maksimal untuk 2 tamu</li>
                                                    <li>Harap datang sesuai dengan shift yang telah ditentukan via WA</li>
                                                </ol>

                                            </p>
                                        </Alert>
                                    </Col>
                                </Item>



                            </Container >
                            <Container fluid id='slider' className='bg-pink p-3 pb-5'>
                                <Item><Col xs={12} md={8}>
                                    <Slider slide={[
                                        gambar('https://i.ibb.co/j9NFmxH/1.jpg', 95,
                                            'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                                        gambar('https://i.ibb.co/SnMxkcs/2.jpg', 95,
                                            'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                                        gambar('https://i.ibb.co/XCpbrPP/3.jpg', 95,
                                            'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),
                                        gambar('https://i.ibb.co/s3dJgXX/4.jpg', 95,
                                            'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1000&h=800'),

                                    ]} />
                                </Col>
                                </Item>
                            </Container>
                            <Container className='bg-green' fluid id='form'>
                                <Item>

                                    <Col xs={10} md={6} className='bg-white rounded poppins mt-3 pt-4 pb-4' data-aos="zoom-in">
                                        <h1 className='sacramento c-main f-small send'>
                                            Send Your Whises
                                        </h1>
                                        <Item>
                                            <form className="col-12 w-100 jefri" style={{ fontSize: '14px' }}>
                                                <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                                                <input ref={this.withfriend} type='text' className="col-12 w-100 text-center" placeholder="Hadir Dengan ..." name='with' />
                                                <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                                                <Item>                             
                                                    <Col xs={6} onClick={() => {
                                                        this.setState({ hadir: true })
                                                    }
                                                    }>
                                                        <input id="option" type="radio" checked={hadir ? true : false} />
                                                        <label ><span><span></span></span>Hadir</label>
                                                        
                                                    </Col>
                                                    <Col xs={6} onClick={() => {
                                                        this.setState({ hadir: false })
                                                    }
                                                    }> 
                                                        <input id="option" type="radio" checked={hadir ? false : true} />
                                                        <label ><span><span></span></span>Tidak Hadir</label>
                                                       
                                                    </Col>     
                                                </Item>

                                                {!hadir ? false : (
                                                    <>  <Alert variant='dark'>
                                                        <Item>
                                                            <div onClick={() => {
                                                                this.setState({ sesi: 1 })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={sesi == 1 ? true : false} className='p-0' />
                                                                <label className='p-0 mb-0'><span><span></span></span>Sesi 1 (11.00 - 12.00 WIB)</label>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <div onClick={() => {
                                                                this.setState({ sesi: 2 })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={sesi == 2 ? true : false} className='p-0' />
                                                                <label className='p-0 mb-0'><span><span></span></span>Sesi 2 (12.00 - 13.00 WIB)</label>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <div onClick={() => {
                                                                this.setState({ sesi: 3 })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={sesi == 3? true : false} className='p-0' />
                                                                <label className='p-0 mb-0'><span><span></span></span>Sesi 3 (13.00 - 14.00 WIB)</label>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <div onClick={() => {
                                                                this.setState({ sesi: 4 })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={sesi == 4 ? true : false} className='p-0' />
                                                                <label className='p-0 mb-0'><span><span></span></span>Sesi 4 (14.00 - 15.00 WIB)</label>
                                                            </div>
                                                        </Item>
                                                        </Alert>
                                                        <Alert variant='info'>
                                                            Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan
                                                        </Alert>
                                                    </>

                                                )}
                                                <Item>
                                                    <Col xs={12} className=''>
                                                        {
                                                            submitted == true ? (
                                                                <Alert variant='success' style={{ fontSize: '14px' }}>
                                                                    Pesan anda sudah disampaikan
                                                                </Alert>) : (submitted === false ? (
                                                                    <Alert variant='danger' style={{ fontSize: '14px' }}>
                                                                        {
                                                                            err.map(val => {
                                                                                return (
                                                                                    <li>{val}</li>
                                                                                )
                                                                            })
                                                                        }

                                                                    </Alert>
                                                                ) : false)
                                                        }

                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <div className='col-6 button rounded btn ' 
                                                    onClick={() => this.handleSubmit()}> Kirim </div>
                                                </Item>
                                            </form>
                                        </Item>
                                    </Col>
                                </Item>

                                <Item>
                                    <h1 className='sacramento c-main f-small send pt-3 pb-3'>
                                        Ghina & Dandy
                                    </h1>
                                </Item>
                            </Container>
                            <Container fluid className='bg-green relative p-0' id='path'>
                                <img className='absolute img-fluid w-100 mx' src={path3} />
                            </Container>
                            <Container fluid className='bg-thite poppins pb-5' id='footer'>
                                <Item>
                                    <h1 data-aos-duration="1000">
                                        Possible Wedding
              </h1>
                                </Item>
                                <Item>
                                    <h2 data-aos-duration="1000">
                                        Digital Invitation
              </h2>
                                </Item>
                                <Item>
                                    <div className='col-3 col-lg-1 p-md-4 btn '
                                        onClick={() => {
                                            window.open('http://instagram.com/possiblewedding')
                                        }}>
                                        <img src={logoig} className='img-fluid w-100'
                                        />
                                    </div>
                                </Item>
                            </Container>
                        </>
                    ) : false}
                </div>
            </>
        )
    }
}